@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');

:root {
  --docpup-complementary-rgb: 255, 205, 225;
  --docpup-complementary: #ffcde1;

  --docpup-dominant: #ffeca0;
  --docpup-accent: #292929;

  --docpup-box-shadow:
    0px 35px 68px 0px rgba(255, 236, 160, 0.5),
    inset 0px -8px 16px 0px rgba(255, 236, 160, 0.6),
    inset 0px 11px 28px 0px rgb(255, 255, 255);

  --docpup-box-shadow-white:
    8px 8px 16px 0 rgba(0, 0, 0, .25),
    /* Outset shadow */
    inset -8px -8px 12px 0 rgba(0, 0, 0, .25),
    /* Dark inset shadow */
    inset 8px 8px 12px 0 rgba(255, 255, 255, 0.4);
  // 0px 35px 68px 0px rgba(0, 0, 0, .25),
  // /* Outset shadow */
  // inset 0px -8px 16px 0px rgba(0, 0, 0, .25),
  // /* Dark inset shadow */
  // inset 0px 11px 28px 0px rgba(255, 255, 255, 0.4);

}

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900) !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// Theme colors
// 60-30-10
// Dominant: #ffeca0 
// Complementary:
// Accent: #ffeca0

$docpup-dominant: #ffeca0 !default;
$docpup-complementary: #ffcde1 !default;
$docpup-accent: #292929 !default;
// $docpup-rose-rgb: rgba(var(--docpup-rose-rgb), 1) !default;



$docpup-blue-green: #8cdfd6 !default;

// remover?
// $docpup-green: #5A716A !default;

// $primary: $blue  !default;
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: (
  "docpup-rose": $docpup-complementary,
  "docpup-yellow": $docpup-dominant,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark) !default;


$input-bg: $white ;
//$card-bg: $docpup-blue-green;
//$navbar-light-color: $docpup-blue-green;
$nav-link-color: $white ;

// body
$body-bg: $docpup-complementary;

// These are the defaults, but you can override any values
$font-family-sans-serif: "Hammersmith One",
  sans-serif;
$font-family-base: $font-family-sans-serif;

// pagination
$pagination-color: $docpup-accent ;
$pagination-active-bg: $docpup-dominant;
$pagination-active-color: $docpup-accent;

@import "~bootstrap/scss/bootstrap";
//@import "~bootstrap/scss/mixin";

//https: //fonts.google.com/selection/embed
// .hammersmith-one-regular {
//   font-family: "Hammersmith One", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

.btn-docpup {
  @include button-variant($white,
    $black,
    $black,
    $docpup-dominant,
    $docpup-dominant,
    $black,

);

border-radius: 50px;
font-size: 1.2rem;
font-family: inherit;
font-weight: 600;
line-height: 1;
text-transform: uppercase;
letter-spacing: 1px;

padding: 16px 32px;


// @media (max-width: 575.98px) {
//   padding: 8px 16px;
// }

@include media-breakpoint-down(sm) {
  padding: 8px 16px;
}
}

.btn-docpup-icon {
  @include button-variant($white,
    $black,
    $black,
    $docpup-dominant,
    $docpup-dominant,
    $black,
  );

  border-radius: 80px;
  padding: 8px 16px;

  @include media-breakpoint-down(sm) {
    border-radius: 50px;
    padding: 4px 8px;
  }
}


.navbar-docpup {

  .navbar-brand {
    // color: $white;
    background-color: $white;
    padding: 8px;
    border-radius: 20px;
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--docpup-accent);
    box-shadow: var(--docpup-box-shadow-white);
    transition: all 0.3s ease;

    &:hover {
      // background-color: darken($docpup-dominant, 20%);
      background-color: $docpup-dominant;
    }
  }

  .navbar-nav .nav-link {
    font-weight: 700;
    line-height: 1;
    font-size: 1rem;
    text-transform: uppercase;
    color: $white;


  }
}


.nav-tabs .nav-link {
  color: #000;

}




@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  // display: none; /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 90000;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */


}

.loader {
  width: 60px;
  height: 60px;
  background-image: url("~/public/img/docpup-logo.png");
  background-size: 60px;
  animation: rotate 2s linear infinite;

  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.claymorphism-item {
  // claymorphism
  // big rounded courners and multiple box-shadows
  // rounded courners
  border-radius: 20px;
  // shadows
  // backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 1);
  box-shadow:
    8px 8px 16px 0 rgba(0, 0, 0, .25),
    /* Outset shadow */
    inset -8px -8px 12px 0 rgba(0, 0, 0, .25),
    /* Dark inset shadow */
    inset 8px 8px 12px 0 rgba(255, 255, 255, 0.4);
}