@import "../../App.scss";

.img-item {
    max-height: 250px;

    // learn to use in this way
    // https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
    //https://getbootstrap.com/docs/5.0/layout/breakpoints/
    // @include media-breakpoint-up(sm) {
    //     max-height: 10px;
    // }

    @media (max-width: 575.98px) {
        max-height: 200px;
    }
}


.item-view {
    border-width: 1.5px;
    border-style: solid;
    border-color: $docpup-accent;
    border-radius: 20px;
    box-shadow: var(--docpup-box-shadow-white);

    a {
        // color: $black;
        // text-decoration: none;
        font-weight: bold;

        // &:hover {
        //     color: $docpup-complementary !important;
        //     text-decoration: $black underline;
        // }
    }
}

.item-view-empty {
    min-height: 300px;
}