@import "../../App.scss";

.products-section {
    background-color: $docpup-complementary !important;
    margin-bottom: 1em;
    border: none !important;
    border-radius: 20px;
    backdrop-filter: blur(5px);
}

.item-result {
    padding: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    width: 250px;
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--docpup-accent);
    background-color: var(--docpup-dominant);
    border-radius: 20px;
    backdrop-filter: blur(5px);
    box-shadow: var(--docpup-box-shadow);
    //efecto en transicion
    // transition: border-width 0.3s ease;
    transition: all 0.3s ease;


    a {
        color: #000;
        text-decoration: none;
        font-weight: bold;

        // &:hover {
        //     color: #fee78d;
        // }
    }

    &:hover {
        background-color: darken($docpup-dominant,20%);
    }
}

@media screen and (max-width: 576px) {
    .item-result {
        width: 300px;
    }
}

.item-price {
    color: red;
    font-size: large;
}